import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { useState, useEffect } from "react"


class HelpPage extends Component {
    state = {
    smallScreen: false,
    modal: { name: "" },
  }
  
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  openModal(e) {
    this.setState({ modal: e })
    document.getElementById("modal").style.display = "block"
  }

  closeModal() {
    document.getElementById("modal").style.display = "none"
  }	
	
  render() {  
	      let indexData = this.props.data.allIndexJson.nodes[0]
    let homeItems = []
    let i = 0    
    indexData.home_items.forEach(item => {
      homeItems.push(
        <div key="uniqueId1"> 
        <div class="flex-1 flex flex-col p-3"><div class="bg-gray px-6 py-10 border-2 rounded-lg shadow-xl">
        <div><h2 id="tier-payg" class="text-center text-2xl leading-8 font-medium text-gray-900">{item.name}</h2>
        <div class="mt-3 flex items-center justify-center"><span class="px-2 flex items-start text-4xl xl:text-5xl leading-none tracking-tight text-gray-900">
        <span class="mt-1 mr-1 text-2xl xl:text-3xl font-medium">EUR</span>
        <span class="font-extrabold">{item.dataitemprice}</span>
        </span>
        <span class="text-xl xl:text-2xl leading-7 font-medium text-gray-500"></span>
        </div></div></div><div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10 border-2 rounded-lg shadow-xl"><ul><li class="flex items-start"><div class="flex-shrink-0"><svg class="h-6 w-6 text-green-500"><use xlinkHref=""></use></svg></div><p class="ml-3 text-base leading-6 font-medium text-gray-500">Toegang tot de 10 audiolessen</p></li><li class="mt-4 flex items-start"><div class="flex-shrink-0"><svg class="h-6 w-6 text-green-500"><use xlinkHref=""></use></svg></div><p class="ml-3 text-base leading-6 font-medium text-gray-500">Registratie en login worden per email toegestuurd.</p></li></ul><div class="mt-8">
<button class="snipcart-add-item block w-full text-center rounded-lg border border-transparent bg-green-500 hover:bg-green-700 text-white px-6 py-3 text-base leading-6 font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
               data-item-id={item.dataitemid}
             data-item-price={item.dataitemprice}
                data-item-url={item.dataitemurl}
               data-item-name={item.dataitemname}
               data-item-description={item.dataitemdescription}
                data-item-image={item.dataitemimage}
               >
                Bestel
              </button>
</div></div></div>
        </div>)
      i++
    })
	  

return (
  <Layout page={"help"}>
  <div className="main">
  <div class="home-main">
    
      <h1 className="title">Bestelhulp</h1>

	  <h3 className="title">Stap 1 : Abonneren</h3>
	
	  <p className="text">
	  Om de lessen te volgen, dient u betalend abonnee worden, door het pakket te bestellen. 
	  </p>
	  <p className="text">
	  U hebt twee keuzes. 3 maanden toegang of 6 maanden toegang.
	  </p>
	  <p className="text">
	  Om te bestellen klikt u op de bestelknop op de homepagina of hier onderaan. Er verschijnt dan een scherm van waaruit u verder kan afrekenen. Dit kan met Bancontact of Credit Card.
	  </p>
	  <div
            className={
              this.state.smallScreen
                ? "grid-container-small"
                : "buy-grid-container"
            }
          >
         
            {homeItems}
     
          </div>
	  
	  <h3 className="title">Stap 2 : Registreren</h3>
	  
	  <p className="text">
	  Eens u een abonnement hebt genomen, kan u een gebruiker en login aanmaken. U dient te registreren met hetzelfde email adres als u de bestelling hebt geplaatst. 
	  U dient daaropvolgend de verificatiecode in te voeren die u per email werd opgestuurd.
	  </p>
	  <p className="text">
	  <center>
	  <Link to="/app/signup"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">Registreren</button></Link>
	  </center>
	  </p>
	  
	 
	  <h3 className="title">Stap 3 : Inloggen</h3>
	  <p className="text">
	  Na het inloggen met de gebruiker en paswoord die u opgaf, kan u op de lessenpagina de audiofragmenten afspelen  
	  </p>
	  <p className="text">
	  <center>
	  <Link to="/app/signup"><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">  Inloggen  </button></Link>
	  </center>
	  </p>
	  
	  <p className="text">Wij danken je voor je vertrouwen en wensen je veel plezier met het volgen van de lessen.</p>
  </div>
  </div>
  
  </Layout>

)
  }
}
export default HelpPage

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        description
        text
        subtitle
        image
        home_items {
          name
          description
          completed
          image
          dataitemid
          dataitemprice
          dataitemurl
          dataitemname
          dataitemdescription
          dataitemimage
        }
      }
    }
    site {
    siteMetadata {
      title
      description
      siteUrl
    }
  }
  }
`

